<div *ngIf="env.variantCard === 0; then classic else modern"></div>

<ng-template #classic>
  <article  class="card w-100"
            [ngClass]="{'on-sale': variant?.product.is_on_sale || variant.product.full_points_offer?.discount_points_price}"
            [routerLink]="['/products', variant.product.slug]">

    <span [ngClass]="{
      'sale-discount': variant.product.is_on_sale || variant.product.full_points_offer?.percent_off_points,
      'sale-discount-points': variant.product.full_points_offer?.percent_off_points && !variant.product.is_on_sale,
      'sale-discount-mixed': variant.product.is_on_sale && variant.product.full_points_offer?.percent_off_points
    }"
    *ngIf="variant.product.is_on_sale || variant.product.full_points_offer?.percent_off_points">
      <span class="sale-discount-points-span" *ngIf="variant.product.full_points_offer?.percent_off_points && !variant.product.percent_off">{{ variant.product.full_points_offer?.percent_off_points }}%<br>OFF<br>CON PUNTOS</span>
      <span *ngIf="variant.product.percent_off && !variant.product.full_points_offer?.percent_off_points">{{ variant.product.percent_off }}% OFF</span>
      <span class="sale-discount-mixed-span" *ngIf="variant.product.percent_off && variant.product.full_points_offer?.percent_off_points  && variant.product.percent_off > variant.product?.full_points_offer?.percent_off_points">Hasta {{ variant.product.percent_off }}% OFF</span>
      <span class="sale-discount-mixed-span" *ngIf="variant.product.percent_off && variant.product.full_points_offer?.percent_off_points  && variant.product?.full_points_offer?.percent_off_points > variant.product.percent_off">Hasta {{ variant.product?.full_points_offer?.percent_off_points }}% OFF</span>
    </span>

    <a  style="background-repeat: no-repeat; background-position: center;"
        class="img d-block"
        [routerLink]="['/products', variant.product.slug]"
        (click)="trackProductClick()"
        [lazyLoad]="variant.picture_medium_l"
        title="{{ variant.product.title }}"
        [offset]="100">
    </a>

    <div class="card-body d-flex flex-column">
      <div class="title-panel d-block">
        <h6 class="d-inline-block w-100">{{ env.name === 'BancoColumbia' ? (variant.product.title | slice:0:23) : variant.product.title }}</h6>
      </div>
      <div class="manufacturer-panel">
        <h6>{{ manufacturerTitle }}</h6>
      </div>
      <div class="shop-panel">
        <h6>{{ shopTitle }}</h6>
      </div>
      <div class="info-panel mt-auto">
        <div #money *ngIf="variant.product.transaction_type === 'reservable' || variant.product.transaction_type === 'purchasable';">
          <div class="wrap-text">
            <span class="price" [ngClass]="{'sale-price': !variant?.product.is_on_sale }" *ngIf="variant.product.regular_price && variant?.product.is_on_sale && !variant.product.full_points_offer?.is_only_full_points">
              {{ store == 'nuuv' ? 'u$s' : '$'}} {{ variant.product.regular_price | floatparse }}
            </span>
            <span class="sale-price" *ngIf="variant.product.regular_price  && !variant?.product.is_on_sale && !variant.product.full_points_offer?.is_only_full_points">
              {{ store == 'nuuv' ? 'u$s' : '$'}} {{ variant.product.regular_price | floatparse }}
            </span>
            <span class="sale-price ml-1" *ngIf="variant.product.is_on_sale && !variant.product.full_points_offer?.is_only_full_points">
              {{ store == 'nuuv' ? 'u$s' : '$'}} {{ variant.product.sale_price | floatparse }}
            </span>
            <span class="price" [ngClass]="{'sale-price': !variant.product.full_points_offer?.discount_points_price }" *ngIf="variant.product.full_points_offer?.is_only_full_points && variant.product.full_points_offer?.original_points_price">
              {{variant.product.full_points_offer?.original_points_price | floatparse}} Puntos
            </span>
            <span class="sale-price ml-1" *ngIf="variant.product.full_points_offer?.is_only_full_points && variant.product.full_points_offer?.discount_points_price">
              {{variant.product.full_points_offer?.discount_points_price | floatparse}} Puntos
            </span>
          </div>
          <span class="installments" *ngIf="env.installments > 1">
            <span *ngIf="store === 'Avenida'; then installmentsGeneric else installmentsAmount"></span>
            <ng-template #installmentsGeneric>
                <small>Comprá en cuotas</small>
            </ng-template>
            <ng-template #installmentsAmount>

              <div *ngIf="variant.product.max_installments_info.installments > 1">
                <small *ngIf="env.name === 'BancoColumbia'">Hasta {{ variant.product.max_installments_info.installments | buildInstallments }} {{ variant.product | buildInstallmentsText }} </small>
                <ng-container *ngIf="env.name !== 'BancoColumbia'">
                  <small *ngIf="!variant.product.full_points_offer?.is_only_full_points" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : '' ">Hasta {{ variant.product.max_installments_info.installments | buildInstallments }} {{ variant.product | buildInstallmentsText }} </small>
                </ng-container>
                <br>
                <span *ngIf="store !== 'Macro'" class="amount cost">${{ buildInstallmentsAmount() | floatparse }}</span>
                <span *ngIf="store === 'Macro' && !variant.product.full_points_offer?.is_only_full_points" class="amount cost">${{ floatParseToInt(buildInstallmentsAmount())}}</span>
              </div>
            </ng-template>
          </span>
          <span class="points-and-price" *ngIf="variant.product.points && !variant.product.full_points_offer?.is_only_full_points">
            <!-- <div *ngIf="store === 'Macro'">
              <hr>
              {{ (macroPoints | floatparse) }} Puntos {{ store === 'Macro' && variant.product.is_on_sale ? ' + $' + (salePrice | floatparse) : '' }}
            </div> -->
            <div *ngIf="store !== 'Macro'">
              <hr>
              {{ (variant.product.points | floatparse) }} Puntos {{  ' + $' + (regularPrice | floatparse) }}
            </div>
          </span>
          <span class="points-and-price" *ngIf="variant.product.full_points_offer?.is_only_full_points">

          </span>
        </div>
        <div #points *ngIf="variant.product.transaction_type === 'voucher' || variant.product.transaction_type === 'points' || variant.product.transaction_type === 'cashback' || variant.product.transaction_type === 'cashback_voucher';">
          <span class="points-price" *ngIf="minPoints">{{ onlyVariant ? "" : "Desde" }} {{ minPoints | floatparse }} Puntos</span>
        </div>
        <div #points *ngIf="variant.product.transaction_type === 'nft'">
          <span class="points-price">{{  variant.product.full_points_offer?.discount_points_price ? (variant.product.full_points_offer?.discount_points_price | floatparse) : (variant.product.full_points_offer?.original_points_price | floatparse) }} Puntos</span>
        </div>
      </div>

    </div>
  </article>
</ng-template>


<ng-template #modern>
  <article id="modern-variant-card" class="card w-100" style="min-width: 100%;" [ngClass]="{'on-sale': variant?.product.is_on_sale}" [routerLink]="['/products', variant.product.slug]">

    <span class="badge on-sale" [ngClass]="env.name === 'BancoNacion' ? 'badge-sale-price' : ''" *ngIf="variant.product.is_on_sale">
      <span>{{ variant.product.percent_off }}% OFF</span>
    </span>

    <a  style="background-repeat: no-repeat; background-position: center;"
        class="img d-block"
        [routerLink]="['/products', variant.product.slug]"
        (click)="trackProductClick()"
        [lazyLoad]="variant.picture_medium_l"
        title="{{ variant.product.title }}"
        [offset]="100">
    </a>

    <div class="card-body d-flex flex-column text-left">
      <div class="title-panel d-block">
        <h6 class="d-inline-block">{{ variant.product.title | titlecase }}</h6>
        <h3 class="m-0">
          <span class="badge badge-light" [ngClass]="env.name === 'BancoNacion' ? 'badge-few-stock' : ''" *ngIf="variant.quantity <= 4"><i class="fa fa-warning mr-1"></i>Quedan Pocos</span>
        </h3>
      </div>
      <div class="manufacturer-panel d-block mt-auto">
        <h6>{{ manufacturerTitle }}</h6>
      </div>
      <div class="shop-panel d-block mt-auto">
        <h6>{{ shopTitle }}</h6>
      </div>
      <div class="info-panel mt-auto">
        <div #money *ngIf="variant.product.transaction_type === 'reservable' || variant.product.transaction_type === 'purchasable';">
          <span class="installments mb-2 mt-2" *ngIf="env.installments > 1">
            <span *ngIf="store == 'Avenida'; then installmentsGeneric else installmentsAmount"></span>
            <ng-template #installmentsGeneric>
                <small><i class="fa fa-credit-card text-muted mr-1"></i>Comprá en cuotas</small>
            </ng-template>
            <ng-template #installmentsAmount>

              <small *ngIf="use_bines">
                <ng-container *ngIf="env.name === 'BancoColumbia'">
                  Hasta {{ variant.product.max_installments | buildInstallments }} {{ variant.product | buildInstallmentsText }}
                </ng-container>
                <ng-container *ngIf="env.name !== 'BancoColumbia'">
                  Hasta {{ variant.product.max_installments_info.installments | buildInstallments }} {{ variant.product | buildInstallmentsText }}
                </ng-container>
                  <br>
                  <span class="amount cost">${{ buildInstallmentsAmount() | floatparse }}</span>
              </small>

              <small *ngIf="!use_bines && getProductMaxPromotion().installments > 1" style="white-space: normal;">
                <span> {{ getProductMaxPromotion().installments > 1 ? 'Hasta' : '' }}</span>
                <span> {{ getProductMaxPromotion().installments }}</span>
                <span> {{ getProductMaxPromotion().installments === 1 ? 'cuota' : 'cuotas' }}</span>
                <span> {{ getProductMaxPromotion().intereses ? 'fijas' : 'sin interés' }}</span>
                de<br>
                <span class="amount cost"> ${{ getProductMaxPromotion().installments_amount | floatparse }}</span>
              </small>

            </ng-template>
          </span>
          <span class="sale-price" *ngIf="variant.product.is_on_sale">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ variant.product.sale_price | floatparse }}
          </span>
          <span class="price" [ngClass]="{'sale-price': !variant?.product.is_on_sale }" *ngIf="variant.product.regular_price">
            {{ store == 'nuuv' ? 'u$s' : '$'}} {{ variant.product.regular_price | floatparse }}
          </span>
          <span class="points-and-price" *ngIf="variant.product.points">
            <hr>
            {{ store == 'Macro' ? (macroPoints | floatparse) : (variant.product.points | floatparse) }} Puntos {{ store == 'Macro' && variant.product.is_on_sale ? ' + $' + (salePrice | floatparse) : ' + $' + (regularPrice | floatparse) }}
          </span>
        </div>
        <div #points *ngIf="variant.product.transaction_type === 'voucher' || variant.product.transaction_type === 'points';">
          <span class="points-price" *ngIf="minPoints">{{ onlyVariant ? "" : "Desde" }} {{ minPoints | floatparse }} Puntos</span>
        </div>
      </div>

    </div>

    <div class="card-footer-badge" *ngIf="variant.product.cucarda">
      <p class="badge-text">
        {{ variant.product.cucarda.descripcion }}
	<img *ngIf="variant?.product?.cucarda?.image" class="logo-bna-modo" [src]="variant.product.cucarda.image" alt="bna cards" width="70" height="15">
      </p>
    </div>
  </article>

</ng-template>
