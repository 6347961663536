import { ModoService } from './../../new-core/core/services/modo.service';
import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, Renderer2, Inject, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {OrdersService} from "../../../services/orders.service";
import {NewsletterService} from "../../../services/newsletter.service";
import { environment } from '../../../environments/environment';
import {DomService} from "../../../services/dom.service";
import { isPlatformBrowser } from '@angular/common';
import { MacroService } from '../../../services/macro.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProviderFormComponent } from '../../dialogs/provider-form/provider-form.component';
import { UrlService } from 'services/url.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild('orderId', { static: false }) orderId: ElementRef;

  env: any = environment;
  landingKey: string;
  cards: boolean = true;
  selecta: boolean;
  showGoTop:boolean;

  constructor(private router: Router,
              private dialog: MatDialog,
              private ordersService: OrdersService,
              private newsletterService: NewsletterService,
              private element: ElementRef,
              private renderer: Renderer2,
              private macroService: MacroService,
              public modoService: ModoService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private authenticationService: AuthenticationService,
              private urlService: UrlService,
            ) { }

  ngOnInit() {
    this.env = environment;
    if ( this.env.name === 'Macro' ) {

      this.macroService.selecta.subscribe((response) => {
        this.selecta = response;
      });
    }
  }


  ngAfterViewInit() {

    if ( !(['Macro', 'BancoNacion'].includes(environment.name)) ) {

      if (isPlatformBrowser(this.platformId)) {
        window.onscroll = () => {
          if (window.pageYOffset >= 50) {
            DomService.show('.back-to-top');
          } else {
            DomService.hide('.back-to-top');
          }
        };
      }

    }

  }

  newsLetter() {
    const messageContainer = this.element.nativeElement.querySelector('.done-msg');
    const inputContainer = this.element.nativeElement.querySelector('.e-mail');
    this.env.email = this.element.nativeElement.querySelector('#emailId').value;

    if (this.env.email === "") {
      return false;
    }

    this.newsletterService.subscription(this.env.email).subscribe(
      (response) => {
        const messageText = this.renderer.createText(response.message);
        this.renderer.setStyle(inputContainer, 'display', 'none' );
        this.renderer.appendChild(messageContainer, messageText);
        this.renderer.setStyle(messageContainer, 'display',  'block');
      },
      (error) => {
        this.renderer.setStyle(inputContainer, 'display', 'block' );
        this.renderer.setStyle(messageContainer, 'display',  'none');
        this.router.navigate(['/'])
      }
    );
  }

  openDataFiscal(e: Event) {
    e.preventDefault();
    let links = [
      "http://qr.afip.gob.ar/?qr=tR6EwozrVSdhu2sUB_vrgw,,",
      "http://qr.afip.gob.ar/?qr=fdO33Lpe2INYFiSkUdRDBw,,",
      'http://qr.afip.gob.ar/?qr=uZXcobL5SN0NX_27SJOePQ,,'
    ];
    links.forEach(element => {
      window.open(element, '_blank');
      });
    }

  backToTop() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  openForm() {
    this.dialog.open(ProviderFormComponent, {
      panelClass: 'provider-form-container',
      width: '700px',
      height: '650px'
    });
  }

  goToMacroAbout() {
    (this.selecta) ? this.router.navigate(['/selecta-about']) : this.router.navigate(['/about']);
  }

  redirection() {
    if(this.env.name === 'MiMoto') {
      this.urlService.navigate('https://www.tiendabna.com.ar/');
  }
}

  goToAyuda() {
    this.urlService.navigate('https://www.tiendabna.com.ar/ayuda', undefined,true);
  }

  goToRegret() {
    this.urlService.navigate('https://www.tiendabna.com.ar/arrepentimiento', undefined, true);
  }
}
