<footer>

  <div class="back-to-top">
    <a href="#" (click)="backToTop()"  class="fa fa-angle-up"></a>
  </div>

  <div class="top py-3">
    <div class="container">
        <div *ngIf="env.name == 'Avenida'; then avenida"></div>
        <div *ngIf="env.name == 'PaseoLibertad'; then libertad"></div>
        <div *ngIf="env.name != 'Avenida' && env.name != 'PaseoLibertad'; then other"></div>

        <ng-template #avenida>
          <nav class="row">
            <ul class="col">
              <li class="my-2"><h6>Customer Service</h6></li>
              <li class="my-2"><a [routerLink]="['/terminos-y-condiciones']">Términos y Condiciones</a></li>
              <li class="my-2"><a [routerLink]="['/politicas-de-privacidad']">Políticas de Privacidad</a></li>
              <li class="my-2"><a [routerLink]="['/preguntas-frecuentes']">Preguntas Frecuentes</a></li>
            </ul>

            <ul class="col">
              <li class="my-2"><h6>¿Necesitas Ayuda?</h6></li>
              <li class="my-2">
                <p>¡Contactate con nosotros!</p><br>
                <a href="tel:+541152639135">Teléfono: {{ env.phone }}</a><br>
                <a href="mailto:{{ env.email }}">Mail: {{ env.email }}</a>
              </li>
              <li class="my-2"><h6><a href="http://blog.avenida.com" target="_blank">Blog</a></h6></li>
            </ul>

            <ul class="col">
              <li class="my-2"><h6>Acceso a Marcas</h6></li>
              <li class="my-2"><a href="https://api.avenida.com.ar/login" target="_blank">Login</a></li>
              <li class="my-2"><a href="https://forms.gle/fvxLYFwN6prTu2Kv9" target="_blank">Vendé con nosotros</a></li>
            </ul>

            <ul class="col newsletter-subscription">
              <li class="my-2"><h6>Newsletter</h6></li>
              <li><p>Dejanos tu email y te mantendremos al tanto de las últimas novedades.</p></li>

              <li #iMailNewsletter>
                <div class="input-group mb-3 e-mail">
                  <input type="email" class="form-control" placeholder="Ingresa tu email" id="emailId" required title="Ingresa tu email">
                  <div class="input-group-append">
                    <button class="btn btn-secondary btn-sm"  (click)="newsLetter()">
                      <i class="fa fa-arrow-circle-right mr-1"></i>
                    </button>
                  </div>
                </div>
                <p class="done-msg">
                  <span class="i-tilde"></span>
                </p>
              </li>
            </ul>
            <ul class="col text-center">
              <li><div *ngIf="true; then qr"></div></li>
              <li><div *ngIf="true; then cace"></div></li>
            </ul>
          </nav>
        </ng-template>

        <ng-template #libertad>
          <nav class="row libertad" id="footernav">

            <ul id="footer-col-1" class="col-md-2 col-12'">
              <li class="my-2">
                <h6>
                  <a [routerLink]="['/terminos-y-condiciones']">Términos y Condiciones</a>
                </h6>
              </li>
              <li class="my-2">
                <h6>
                  <a href="https://paseolibertadonline.zendesk.com/hc/es" target="_blank">Preguntas frecuentes</a>
                </h6>
              </li>
              <li class="my-2">
                <h6>
                  <a [routerLink]="['/privacidad-y-proteccion']">Política de privacidad y política de protección de datos personales</a>
                </h6>
              </li>
              <li class="my-2" *ngIf="env.consumer_defense">
                <h6>
                  <a href="{{ env.consumer_defense }}" target="blank">Defensa de las Personas Consumidoras<br>Para reclamos ingrese aquí</a>
                </h6>
              </li>
            </ul>

            <ul id="footer-col-2" class="col-md-2 col-12">
              <li class="my-2">
                <h6>
                  <a href="https://grupolibertad.com.ar/paseos-comerciales">¿Quienes somos?</a>
                </h6>
              </li>
              <li class="my-2">
                <h6>
                  <a href="https://grupolibertad.com.ar/paseos-comerciales" target="_blank">Nuestras Sucursales</a>
                </h6>
              </li>
              <li class="my-2">
                <h6>
                  <a href="https://forms.gle/fvxLYFwN6prTu2Kv9" target="_blank">Vendé con nosotros</a>
                </h6>
              </li>
            </ul>


            <ul id="footer-col-3" class="col-md-3 col-12'">
              <li class="my-2">
                <h6>
                  Atencion al cliente
                </h6>
              </li>
              <li class="my-2">
                <p>Si tenes alguna consulta, comunicate con nuestro centro de atención al cliente</p>
              </li>
              <li class="my-2">
                <p>
                  Lunes a viernes de 9 a 18 hs
                  (011) 5263-9483
                  ayuda@paseolibertadonline.com.ar
                </p>
              </li>
            </ul>

            <br class="brakeMobile">

            <ul id="qr-promo" class="col-12 col-md-2 text-center">
              <li id="qr-code"><div *ngIf="env.dataFiscal; then qr"></div></li>
              <li id="ahora-promo"><img class="mx-auto d-block mt-2 mb-3 w-100" style="max-width: 115px;" src="/assets/images/logo-ahora-12.png" alt="logo ahora 12"/></li>
              <li id="cace-promo"><img class="mx-auto d-block mt-2 mb-3" style="max-width: 115px;" src="/assets/images/logo-CACE.png" alt="logo CACE"/></li>
            </ul>

            <br class="brakeMobile">

            <ul id="logo-footer" class="col-12 col-md-2 text-center">
              <div class="d-inline">
                <img class="img-fluid" [src]="selecta ? '/assets/stores/current_store/images/store-logo-selecta-footer.png' : '/assets/stores/current_store/images/store-logo-footer.svg'" alt="logo store"/>
              </div>
            </ul>

          </nav>
        </ng-template>

        <ng-template #other>
          <nav class="row other justify-content-between align-items-center" id="footernav">
            <ul id="terms-and-policy" class="col-md-2" [ngClass]="env.name === 'Macro' ? 'col-4' : 'col-12'">
              <li class="my-2">
                <h6>
                  <a [routerLink]="env.name === 'Macro' ? ['/terms']:['/terminos-y-condiciones']">Términos y Condiciones</a>
                </h6>
              </li>
              <ng-container *ngIf="env.consumer_defense && (env.name === 'BancoNacion' || env.name === 'EspecialesBNA')">
                <li class="my-2">
                    <h6>
                      <a [routerLink]="['/reclamos']">
                        Defensa al consumidor, empezá aquí tu reclamo
                      </a>
                    </h6>
                </li>

              </ng-container>

              <ng-container *ngIf="env.name === 'BancoCiudad'">
                <li class="my-2">
                  <h6>
                    <a href="{{ env.regret_buy }}" target="blank">Botón de arrepentimiento</a>
                  </h6>
                </li>
              </ng-container>

            </ul>

            <ul id="faq" class="col-md-2" [ngClass]="env.name === 'Macro' ? 'col-4' : 'col-12'">
              <li class="my-2">
                <h6>
                  <a *ngIf="env.name !== 'BancoCiudad' && env.name !== 'EspecialesBancoCiudad' && env.name !== 'BancoColumbia' && env.name !== 'Sport Club'" [routerLink]="env.name === 'BancoNacion' || env.name === 'EspecialesBNA' || env.name === 'Macro' ? ['/faqs']:['/preguntas-frecuentes']">Preguntas frecuentes</a>
                  <a *ngIf="env.name === 'Sport Club'" [routerLink]="['/faqs']">Preguntas frecuentes</a>
                  <a *ngIf="env.name === 'BancoCiudad' || env.name === 'EspecialesBancoCiudad'" href="https://gestiones.zendesk.com/hc/es" target="_blank">Centro de ayuda</a>
                  <a *ngIf="env.name === 'BancoColumbia'" href="https://tiendacolumbia.zendesk.com/hc/es" target="_blank">Preguntas frecuentes</a>
                </h6>
              </li>
              <li *ngIf="env.name === 'BancoNacion'" class="my-2"><h6><a href="https://altacomercios.tiendabna.com.ar/" target="_blank">Vendé con nosotros</a></h6></li>
            </ul>

            <ul id="sellers-contact" class="col-12 col-md-2">
              <li *ngIf="env.name === 'Sport Club'" class="my-2"><h6><a href="https://www.sportclub.asociate.club/sportclub/comercios#hero-overlay-form" target="_blank">Sumá Tu Comercio</a></h6></li>
              <li *ngIf="env.name !== 'BancoNacion' && env.name !=='Sport Club' && env.name !== 'BancoCiudad' && env.name !== 'BancoColumbia'" class="my-2"><h6><a href="https://forms.gle/fvxLYFwN6prTu2Kv9" target="_blank">Vendé con nosotros</a></h6></li>
              <li *ngIf="env.name === 'BancoCiudad'" class="my-2"><h6><a href=" https://forms.gle/jLLwi7FFtUeJGvcC9" target="_blank">Vendé con nosotros</a></h6></li>
              <li *ngIf="env.name === 'BancoColumbia'" class="my-2"><h6><a href="https://forms.gle/Y6ynjwwy19AgsuV36" target="_blank">Vendé con nosotros</a></h6></li>
              <!--New layout for BNA-->
              <ng-container *ngIf="env.name === 'BancoNacion' || env.name == 'EspecialesBNA'">
                <li class="my-2" ><h6><a href="{{ env.regret_buy }}" target="blank">Botón de arrepentimiento</a></h6></li>
                <li class="my-2" ><h6><a href="https://www.nacion-seguros.com.ar/formulario-de-arrepentimiento/" target="blank">Botón de arrepentimiento seguros</a></h6></li>
                <li class="my-2" ><h6><a href="https://semananacion.com.ar/semananacion" target="_blank">Descuentos y Beneficios BNA</a></h6></li>
              </ng-container>

            </ul>

            <ul id="newsletter" class="col-12 col-md-2" >
              <li class="my-2"><h6><a href="https://bit.ly/37tolqf" target="_blank">Inscribite al newsletter</a></h6></li>
            </ul>

            <ul id="points-instructions" class="col-4 col-md-2" >
              <li class="my-2"><h6><a (click)="goToMacroAbout()" routerLink="">¿Cómo sumar puntos?</a></h6></li>
            </ul>

            <ul id="oreder-tracking" class="col-12 col-md-2" *ngIf="env.name !== 'BancoColumbia'">
              <li class="my-2"><h6><a [routerLink]="['/tracking']">Seguimiento de orden</a></h6></li>
            </ul>

            <ul class="col-12 col-md-2 text-center" *ngIf="env.name === 'BancoColumbia'">
              <li class="my-2">
                <h6 class="text-center"><a href="{{ env.regret_buy }}" target="blank">Botón de arrepentimiento</a></h6>
              </li>
            </ul>

            <ul class="col-12 col-md-2 text-center" *ngIf="env.name === 'BancoColumbia'">
              <li class="my-2">
                <h6 class="text-center"><a href="https://tiendacolumbia.zendesk.com/hc/es/requests/new?ticket_form_id=28520688923411" target="blank">Verificación de compra</a></h6>
              </li>
            </ul>

            <ul id="regret" class="col-12 col-md-2">

              <li class="my-2"*ngIf="env.name == 'MiMoto'">
                <h6><a (click)="goToRegret()" style="cursor: pointer;" target="_blank">Botón de arrepentimiento</a></h6>
              </li>

              <li class="my-2"
                  *ngIf="!selecta
                  && env.name !== 'MiMoto'
                  && env.name !== 'BancoNacion'
                  && env.name !== 'EspecialesBNA'">
                  <h6><a href="{{ env.regret_buy }}" target="blank">Botón de arrepentimiento</a></h6>
              </li>


              <li class="my-2"
                  *ngIf="env.name === 'Macro'
                  && selecta">
                  <h6><a href="/devoluciones-selecta" target="blank">Botón de arrepentimiento</a></h6>
              </li>

              <li id="help" class="my-2" *ngIf="env.name == 'BancoNacion' || env.name == 'EspecialesBNA'"><h6><a href="/ayuda">Ayuda</a></h6></li>
              <li id="help" class="my-2" *ngIf="env.name == 'MiMoto'"><h6><a (click)="goToAyuda()" style="cursor: pointer;" target="_blank">Ayuda</a></h6></li>
              <li id="help" *ngIf="env.name == 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'"><h6>Atención al cliente:</h6></li>
              <li id="help" *ngIf="env.name == 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'"><h6>0810 4444 500</h6></li>
              <li id="help" class="my-2" *ngIf="env.name == 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'"><h6>Lunes a Sábado de 8hs a 20hs</h6></li>
            </ul>

            <ul id="regret" class="col-12 col-md-2">
              <li class="my-2" *ngIf="env.name === 'Macro' && !this.authenticationService.isLoggedIn()">
                <h6><a href="https://sacatutarjeta.macro.com.ar/peditutarjeta?utm_source=MacroPremia&utm_medium=Web&utm_campaign=peditutarjeta_Performance_TC_MA&utm_content=DemandGen_TP_NotSet_NotSet" target="_blank">Sacá tu tarjeta</a></h6>
              </li>
            </ul>

            <br class="brakeMobile">

            <ul id="qr-promo" class="col-12 col-md-2 text-center">
              <li id="qr-code"><div *ngIf="env.dataFiscal; then qr"></div></li>
              <li id="ahora-promo"><img class="mx-auto d-block mt-2 mb-3 w-100" style="max-width: 115px;" src="/assets/images/logo-ahora-12.png" alt="logo ahora 12"/></li>
              <li id="cace-promo"><img class="mx-auto d-block mt-2 mb-3" style="max-width: 115px;" src="/assets/images/logo-CACE.png" alt="logo CACE" /></li>
            </ul>

            <br class="brakeMobile">

            <ul id="logo-footer" class="col-12 col-md-2 text-center">
              <div class="d-inline">
                <img (click)="redirection()" [ngClass]="{'redirect': env.name === 'MiMoto'}" class="img-fluid" [src]="selecta ? '/assets/stores/current_store/images/store-logo-selecta-footer.png' : '/assets/stores/current_store/images/store-logo-footer.svg'" alt="logo store"/>
              </div>
            </ul>
          </nav>

        </ng-template>

        <ng-template #qr>
          <span *ngIf="env.name !== 'Macro' && env.name !== 'Sport Club'">
            <a href="{{ env.dataFiscal }}" rel="nofollow" target="_F960AFIPInfo">
              <img class="m-1" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR Store" width="40">
            </a>
            <a *ngIf="env.name !== 'BancoColumbia'" href="https://www.argentina.gob.ar/aaip" rel="nofollow" target="_blank">
              <img class="m-1" src="/assets/images/avenida/footer/aaip.png" alt="Isologo Avenida" width="40">
            </a>
          </span>

          <span *ngIf="env.name === 'Macro'">
            <a href="http://qr.afip.gob.ar/?qr=CUgOBTpQl28H-1HLwfMZKg,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=tR6EwozrVSdhu2sUB_vrgw,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=t6gIVXwpEgKop4JFYmhF9Q,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=3Ez_fhoJPDuwq0TGiGi20Q,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=r_YZ2wQDFhlW5R0Mn2DEHA,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=EeMk1Z1r-0358Ek_ycU7fA,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt="QR seller" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=zi9yOQ9T3DlVQVYguRzjNw,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=Cdf7ZcVdItSD8m0fZb1tEg,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="40">
            </a>

            <a href="http://qr.afip.gob.ar/?qr=6yfzp1tq0Nwj8OeEv6mA0A,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="40">
            </a>

             <a href="http://qr.afip.gob.ar/?qr=itOyebuOTXYJL_D9DfH1JA,," target="_F960AFIPInfo">
              <img class="m-1 mb-4" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="40">
            </a>
          </span>

        </ng-template>

      </div>
  </div>

  <div class="bottom py-3">
    <div class="container">
      <div class="row no-gutters align-items-md-center text-center justify-content-between align-items-center">
        <!-- Cards -->
        <div id="payment-title" class="col-12 col-md-auto">
          <div class="mr-3 my-2">
            <p>Métodos de pago</p>
          </div>
        </div>
        <div class="col-12 col-md-auto" *ngIf="env.name !== 'BancoNacion'" [ngClass]="env.name === 'Macro' ? 'payment-methods-2x' : 'payment-methods'">
          <ul id="payment"class="cards list-inline mx-auto">
            <ng-container *ngIf="(modoService.mostrarPagoModo$ | async) && env.name === 'Macro'">
              <img style="vertical-align: super" src="assets/images/logo-MODO.svg" alt="logo-modo" width="90">
            </ng-container>
            <li id="visa-debito" class="visa-debito list-inline-item" title="Visa Débito"></li>
            <li id="visa" class="visa list-inline-item" title="Visa"></li>
            <li id="mastercard" class="mastercard list-inline-item" title="MasterCard"></li>
            <li id="american" class="american list-inline-item" title="American Express"></li>
            <li id="todo_pago" class="todo_pago list-inline-item" title="TodoPago"></li>
            <li id="sps-decidir" class="decidir list-inline-item" title="SPS Decidir"></li>
          </ul>
        </div>
        <!-- End Cards -->

        <!-- BNA Cards -->
        <div id="bna"  *ngIf="env.name == 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'" class="col-12 col-md-auto no-gutters align-items-md-center text-center" style="max-width: 485px;">
          <div class="mw-100">
            <div class="col-12 col-md-auto">
              <div class="row align-items-center justify-content-center row-cards-bna">
                <div class="d-flex flex-column align-items-start mt-1">
                  <span class="mb-1">
                    Crédito
                  </span>
                  <img class="img-bna-cards" src="/assets/stores/current_store/images/images-cards-footer/credito_footer.png" alt="bna cards" width="115">
                </div>
                <div class="d-flex flex-column align-items-start mt-1">
                  <span class="mb-1">
                    Débito
                  </span>
                  <section>
                    <img class="img-bna-cards" src="/assets/stores/current_store/images/images-cards-footer/maestro_footer.png" alt="bna cards" width="145">
                  </section>
                </div>
                <ng-container *ngIf="(modoService.mostrarPagoModo$ | async)">
                  <div class="d-flex flex-column align-items-start mt-1">
                    <span class="mb-1">
                      App
                    </span>
                    <img class="img-bna-cards" src="/assets/stores/current_store/images/images-cards-footer/MODO_footer.png" alt="bna cards" width="115">
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- End BNA Cards -->

        <!-- Compra Segura -->
        <div id="safe-buy" class="col-12 col-md-auto">
          <div class="my-2">
            <p>Compra Segura <i class="fa fa-shield"></i></p>
          </div>
        </div>
        <!-- End Compra Segura -->

      <!-- HotSale
        <div class="col-12 col-md-auto mx-sm-0 mx-md-4" *ngIf="env.name === 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'" href="#" target="_blank">
          <img src="/assets/images/hotsale.png" width="90">
        </div>
      -->

        <div class="col-12 col-md-auto mx-sm-0 mx-md-4" *ngIf="env.cybermonday" href="#" target="_blank">
          <img src="/assets/images/cybermonday.svg" alt="cybermonday" width="90">
        </div>

        <div class="col-12 col-md-auto mx-sm-0 mx-md-4" *ngIf="env.name == 'BancoNacion' || env.name == 'MiMoto' || env.name == 'EspecialesBNA'" href="#" target="_blank">
          <img class="mx-auto d-block mt-2 mb-3" alt="cace logo" style="max-width: 115px;" src="/assets/images/cacelogo.png"/>
        </div>

        <!-- Email -->
        <div id="footer-email" class="col-12 col-md-auto" *ngIf="env.email">
          <div class="mr-3 my-2">
            <a href="mailto:{{ env.email }}">{{ env.email }} <i class="fa fa-envelope"></i></a>
          </div>
        </div>
        <!-- End Email -->

        <!-- Telephone -->
        <div id="footer-phone" class="col-12 col-md-auto" *ngIf="env.phone && env.name !== 'BancoColumbia'">
          <div class="mr-3 my-2">
            <p>Teléfono: {{ env.phone }}</p>
          </div>
        </div>
        <!-- End Telephone -->

        <!-- Social -->
        <div class="col-12 col-md-auto" *ngIf="env.social">
          <div class="mr-3 my-2">
            <a *ngIf="env.instagram" href="https://www.instagram.com/{{ env.instagram }}" target="blank" class="mx-2"><i class="fa fa-instagram"></i></a>
            <a *ngIf="env.facebook" href="https://www.facebook.com/{{ env.facebook }}" target="blank" class="mx-2"><i class="fa fa-facebook"></i></a>
            <a *ngIf="env.twitter" href="https://www.twitter.com/{{ env.twitter }}" target="blank" class="mx-2"><i class="fa fa-twitter"></i></a>
          </div>
        </div>
        <!-- End Social -->

        <div class="col-12 col-md-auto" *ngIf="env.consumer_defense && (env.name !== 'BancoNacion' && env.name !== 'EspecialesBNA')">

          <a  href="{{ env.consumer_defense }}"
              target="blank"
              class="mx-2">
              Defensa de las Personas Consumidoras<br>Para reclamos ingrese aquí
          </a>

        </div>
      </div>

      <div class="row no-gutters align-items-md-center text-center" id="taxes-bottom" *ngIf="env.cards && env.name !== 'BancoNacion' && env.name !== 'EspecialesBNA' && env.name !== 'MiMoto'">

        <!-- CFT -->
        <div class="col-12 col-md-auto">

          <div class="my-3">

            <p>
              <span class="mr-1 cft" *ngIf="env.cft">CFT: {{ env.cft }}</span>
              <span class="mr-1 cftna" *ngIf="env.cftna">CFTNA: {{ env.cftna }}</span>
              <span class="mr-1 tna" *ngIf="env.tna">TNA: {{ env.tna }}</span>
              <span class="mx-1"></span><br class="brakeMobile">
              <span class="mb-1" style="line-height: 25px;" *ngIf="env.cft">CFT: Costo Financiero Total &nbsp;</span><br class="brakeMobile">
              <span class="mb-1" style="line-height: 25px;" *ngIf="env.cftna">CFTNA: Costo Financiero Total Nominal Anual &nbsp;</span><br class="brakeMobile">
              <span class="mb-1" style="line-height: 25px;" *ngIf="env.tna">TNA: Tasa Nominal Anual &nbsp;</span>
            </p>

          </div>

        </div>
        <!-- End CFT -->


      <!-- HOTSALE      -->

        <div class="col-12 col-md-auto mx-sm-0 mx-md-4" *ngIf="env.hotsale" href="#" target="_blank">
          <img src="/assets/images/hotsale.png" width="90">
        </div>



      <!-- Cybermonday -->

        <div class="col-12 col-md-auto mx-sm-0 mx-md-4" *ngIf="env.cybermonday" href="#" target="_blank">
          <img src="/assets/images/cybermonday.svg" width="90" alt="logo promo">
        </div>


      </div>

    </div>
  </div>

  <div *ngIf="env.whatsapp" class="btn-whatsapp fixed-bottom">
    <a href="https://wa.me/{{ env.whatsapp }}" target="_blank"><img class="m-2" src="/assets/images/whatsapp.svg" alt="whatsapp" width="40" height="40"></a>
  </div>

</footer>
